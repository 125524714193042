import React from 'react'
import { Container, Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '../../../Button'
import { useStyles } from './style'

const Faq = props => {
    const { title, QandA, faqPage } = props
    const classes = useStyles(props)
   

    return (
        <Box id='faq' position='relative' paddingTop={faqPage ? '0' : '5rem'} paddingBottom="6.25rem" className={classes.root}>
            <Container maxWidth={'lg'}>
                {!faqPage &&
                    <Typography variant="h2" align="center" gutterBottom>
                        {title}
                    </Typography>
                }
                <Container maxWidth={faqPage ? "md" : "sm"}>
                    <Box margin="2.5rem auto">
                        {QandA.map((faq, key) => (
                            <Accordion
                                className={classes.paper}
                                key={key}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon fontSize="large" />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography variant='h5' className={classes.headerText}>
                                        {faq.question} ?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box>
                                        {faq.answer}
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Box>
                    {!faqPage &&
                        <Box marginTop="3rem" textAlign="center">
                            <Button variant="outlined" size="large" link to="faq" color="primary">
                                View All
                            </Button>
                        </Box>
                    }
                </Container>
            </Container>
        </Box>
    )
}

Faq.propTypes = {

}

export default Faq
