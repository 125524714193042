import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: props => props.faqPage ? '#FFFFFF' : '#F2F8FF',
    },
    paper: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        borderBottom: '1.5px solid #FFFFFF',
        '&::before':{
            backgroundColor: 'transparent'
        }
    }
}))

export { useStyles }